<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to=""
            @click.native="$router.go(-1)"
            >栏目管理 /
          </router-link>
          <span class="crumbs_item crumbs_last" v-if="!id">{{
            "新建"
          }}</span>
          <span class="crumbs_item crumbs_last" v-else>{{
            "编辑"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <a-form ref="ruleForm" :form="form" @submit="handleSubmit">
        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">栏目：</span>
            <div class="right_Div">
              <a-input
                autocomplete="off"
                class="all_input"
                placeholder="请输入栏目名称"
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入栏目名称' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">编码：</span>
            <div class="right_Div">
              <a-input
                autocomplete="off"
                class="all_input"
                placeholder="请输入编码"
                v-decorator="[
                  'columnCode',
                  { rules: [{ required: true, message: '请输入编码' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name ">介绍：</span>
            <div class="right_Div">
              <a-textarea
                autocomplete="off"
                :auto-size="{ minRows: 3 }"
                class="all_input"
                placeholder="请输入介绍"
                v-decorator="['detail']"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name">图片：</span>
            <div class="right_Div required">
              <span class="ant-upload-picture-card-wrapper" v-if="banner">
                <div
                  class="
                    ant-upload
                    ant-upload-select
                    ant-upload-select-picture-card
                  "
                >
                  <span role="button" tabindex="0" class="ant-upload">
                    <div class="pictures">
                      <img
                        style="max-height: 100px; max-width: 100px"
                        :src="banner"
                        alt="avatar"
                      />
                      <div class="icon">
                        <a
                          :href="banner"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <a-icon type="eye" class="i" />
                        </a>
                        <a-icon
                          @click="banner = ''"
                          type="delete"
                          class="i"
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </span>
              <a-upload
                v-else
                name="app"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <div>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name ">描述：</span>
            <div class="right_Div">
              <a-textarea
                autocomplete="off"
                :auto-size="{ minRows: 3 }"
                class="all_input"
                placeholder="请输入描述"
                v-decorator="['overview']"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name ">备注：</span>
            <div class="right_Div">
              <a-textarea
                autocomplete="off"
                :auto-size="{ minRows: 3 }"
                class="all_input"
                placeholder="请输入备注"
                v-decorator="['remark']"
              />
            </div>
          </div>
        </a-form-item>
   

        <div class="button">
          <a-button type="primary" class="btn" html-type="submit" :loading="submitLoading"
            >保存</a-button
          >
          <a-button class="all_boder_btn btn" @click="$router.go(-1)"
            >取消</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      submitLoading:false,
      loading:false,
      banner:'',
      id:'',
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  }, // 事件处理器
  methods: {
    // 查询数据详情
    getDetail(){
      this.$ajax({
        url: '/hxclass-management/ProductColumn/detail/' + this.id,
      }).then(res=>{
        if(res.code == 200 && res.success){
          let obj = res.data
          this.$nextTick(() => {
            this.form.setFieldsValue({
              title: obj.title, //标题
              columnCode: obj.columnCode, //摘要
              detail: obj.detail, //介绍
              overview: obj.overview, //描述
              remark: obj.remark, //备注
            });
            this.banner = obj.banner
          });
        }
      }).catch(err=>{
        
      })
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.pid = 0
          values.banner = this.banner
          if(this.id){
            values.id = this.id
          }
          this.onSubmit(values);
        } else {
          this.$message.warning("请检查必填项是否填写完整！");
        }
      });
    },
    // 提交
    onSubmit(value) {
      // 提交中
      if (!!this.submitLoading) {
        return
      }

      this.submitLoading = true //提交中
      this.$ajax({
        url: '/hxclass-management/ProductColumn/update',
        method: 'post',
        params: value
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.success("成功");
          this.$router.go(-1);
          this.submitLoading = false
        }else{
          this.$message.error(res.message)
        }
      })

    },
    // 上传前钩子
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      const fileName = fileData.filename // 名称前缀
      let picture = fileName + 'Img'

      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.banner = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    
  }, // 生命周期-实例创建完成后调用
  created() {
    this.id = Number(this.$route.query.id)
    if(this.id){
      this.getDetail()
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-form-item {
  margin: 24px;
}
/deep/.all_content_box .all_input {
  height: 37px;
}
/deep/.ant-input-number-input {
  height: 37px;
  padding: 0;
}
/deep/.ant-form-explain {
  margin-left: 160px;
}
/deep/.ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 40px;
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      // margin: 24px;
      .greytext {
        font-size: 14px;
        color: #888;
      }

      .all_left_name {
        width: 140px;
        text-align: right;
      }
      .all_left_info {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 20px;
        width: 600px;
        margin-top: 10px;
      }

      .right_Div {
        .inneer_img {
          width: 100px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .cascader-input,
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
          min-width: 500px;
        }
      }
      .editor{
        min-width: 1200px;
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.pictures .icon {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pictures:hover {
  .icon {
    opacity: 1;
  }
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.remark{
  padding-top: 50px;
  padding-left: 50px;
  color: red;
}
</style>
